<template>
  <div class="wrap" v-loading="loading">
    <div v-if="!checkUpdateTable.data.length" class="upload-wrap">
      <UploadExcel
        @uploadSuccess="onUploadSuccess"
      />
    </div>
    <!-- 质检更新 -->
    <div v-else class="check-update">
      <div class="check-update-message">
        {{ checkUpdateTable.message ?? '' }}
      </div>
      <el-table
        :data="checkUpdateTable.data"
        border
        stripe
        style="width: 100%">
        
        <el-table-column
          prop="字典名称"
          label="数据列名"
          width="180"
          sortable
          :filters="checkUpdateTable.dataColNameFilters"
          :filter-method="dataColNameFilterHandler">
                <template slot="header" scope="scope">
            <el-tooltip content="点击向下箭头可以进行筛选显示" placement="left" effect="dark">
              <span>数据列名</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="原始名称"
          label="上传内容"
          width="180">
        </el-table-column>
        <el-table-column
          prop="识别名称"
          label="识别内容">
        </el-table-column>
        <el-table-column
          label="修改内容"
          width="150">
          <template slot-scope="{ row }">
            <DictSelect
              :code="row['字典编号']"
              v-model="row.value" />
          </template>
        </el-table-column>
        <el-table-column
          prop="次数"
          label="次数">
        </el-table-column>
        <el-table-column
          prop="相识度"
          label="相识度">
          <template slot-scope="{ row }">
            {{ row['相识度'] }}%
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120">
          <template slot-scope="{ row }">
            <el-button type="primary"  @click="onCheckUpdate(row)">质检修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="foot">
      <el-button
        v-if="checkUpdateTable.data.length"
        type="primary"
        @click="importGoOn">
        继续录入
      </el-button>
    </div>
  </div>
</template>

<script>
import * as dSampleApi from '@/api/dSample'
import DictSelect from '@/components/DictSelect.vue';
import UploadExcel from './components/UploadExcel.vue'

export default {
  data() {
    return {
      loading: false,
      checkUpdateTable: {
        message: '', // 提交结果
        data: [], // 质检修改talble数据
        dataColNameFilters: [] // 数据列名筛选
      }
    }
  },
  components: {
    DictSelect,
    UploadExcel
  },
  methods: {
    onUploadSuccess(res) {
      if (res.status) {
          let data = JSON.parse(res.data)
          data.forEach(item => {
            item.value = ''
            item.val = ''
          })
          console.log('data', data)
          let dataColNames = data.map(item => item['字典名称'])
          dataColNames = Array.from(new Set(dataColNames))
          this.checkUpdateTable.dataColNameFilters = dataColNames.map(val => {
            return {
              text: val,
              value: val
            }
          })
          this.checkUpdateTable.data = data
          var vv =res.message
          this.$message.warning({
            message: '自动质检后，发现可能存在的问题数据，请人工修改确认'
          })

          this.checkUpdateTable.message = vv
        } else {
          this.$message.error('提示：'+res.message)
          this.checkUpdateTable.data = []
          this.checkUpdateTable.message = ''
        }
    },
    onCheckUpdate(row) {
      console.log(row)
      if (!row.value) {
        this.$message.error('请选择修改名称')
        return
      }
      this.loading = true
      dSampleApi.zdyDataCheckUpdate({
        zdname: row['字段名称'],
        importq: row['原始名称'],
        zdvalue: row.value,
        keyid: row.keyid
      }).then(res => {
        this.loading = false
        if (res.status) {
          this.$message.success(res.message)
        }
      }).catch(err => {
        this.loading = false
      })
    },
    dataColNameFilterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    importGoOn() {
      this.reset()
    },
    reset() {
      this.checkUpdateTable.data = []
      this.message = []
    }
  },
  mounted() {
  
  }
}
</script>

<style lang="less" scoped>
.wrap {
  background-color: #fff;
  .upload-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
  }
  .check-update {
    padding: 20px;
    min-height: 500px;
    &-message {
      margin-bottom: 12px;
      color: #67C23A;
    }
  }
  .foot {
    padding: 20px;
    border-top: 1px solid #DCDFE6;
  }
}
</style>
